.adherent-dashboard {
    display: grid;
    row-gap: 1em;
    column-gap: 1em;
    min-height: 0;
    min-width: 0;
    grid-template:
            "a a a b b"
            "a a a b b"
            "a a a d d"
            "a a a d d"
            "c c c e e"
            "c c c e e"
            "c c c e e"
            "c c c e e"
            "c c c e e";
}

@media screen and (max-width: 1600px) {
    .adherent-dashboard {
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 1200px) {
    .adherent-dashboard {
        grid-template:
                "a"
                "a"
                "a"
                "b"
                "b"
                "b"
                "c"
                "d"
                "e"
                "f"
    }
}

.a-news-dashboard {
    grid-area: a;
    min-height: 300px;
}

.a-activity-dashboard {
    grid-area: b;
    min-height: 160px;
}

.a-agenda-dashboard {
    grid-area: c;
}

.a-quick-access-dashboard {
    grid-area: d;
}

.a-monthly-results-dashboard {
    grid-area: e;
    min-height: 445px;
    overflow: hidden;
    min-width: 0;
}

.list-thumbnail {
    max-height: 60px;
}