/* Colors */
$primary-red: #d01408;
$dark-red: #af2912;
$light-red: #e13c32;
$blue: #2c50a0;
$green: #36b74f;
$white: #fff;
$grey-100: #f4f5f9;
$grey-200: #9eaacf;
$grey-300: #8d9bba;
$grey-400: #8890a1;
$grey-500: #4e5b74;
$grey-600: #4d5463;
$grey-700: #282c36;
$grey-800: #1f232c;
$black: #000;

.rotate-90 {
  transform: rotate(90deg);
}

.container-with-menu {
  margin-left: 100px;
}

.header-with-menu {
  margin-left: -50px;
  width: calc(100vw - 150px - 1rem);
}

@media screen and (max-width: 768px) {
  .container-with-menu {
    margin-left: 0;
  }

  .header-with-menu {
    margin-left: calc(90px - 1rem);
    width: calc(100vw - 90px - 0.5rem);
  }
}

.min-height-full-screen {
  min-height: 100vh;
}

.width-fit-content {
  width: fit-content;
}

.top-radius {
  border-radius: 5px 5px 0 0;
}

.bottom-radius {
  border-radius: 0 0 5px 5px;
}

.text-align-left {
  text-align: left;
}

.line {
  width: 100%;
  height: 1px;
  background-color: $grey-200;

  &.light {
    background-color: #edeef1;
  }

  &.large {
    height: 5px;
    background-color: $grey-100;
  }
}

.ws-nowrap {
  white-space: nowrap;
}

.ws-pre-wrap {
  white-space: pre-wrap;
}

.word-break-word {
  word-break: break-word;
}

.grid-item {
  margin-bottom: 24px;
}

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: $grey-800;
  color: $white;
  font-size: 10px;
  text-align: center;
  padding: 2px 5px;
  border-radius: 5px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  top: 100%;

  &.right {
    top: 0;
    left: 105%;
    font-size: 14px;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.bg-cover {
  background-size: cover;
  background-position: center;
}

.bg-contain {
  background-size: contain;
}

.max-list-container-width {
  max-width: 1329px;
}

.line-through {
  text-decoration: line-through;
}

.red-svg {
  path {
    fill: $primary-red;
  }
}

.absolute-top-right-corner {
  position: absolute;
  top: 0;
  right: 0;
}

.absolute-right-corner {
  position: absolute;
  right: 0;
}

.small-line-height {
  line-height: 18px;
}

.radius-20 {
  border-radius: 20px;
}

.with-polygon {
  .btn-primary {
    polygon {
      fill: $white;
    }

    &::after {
      background: $light-red;
    }
  }

  .btn-outline {
    polygon {
      fill: $primary-red;
    }

    &::after {
      polygon {
        fill: $primary-red;
      }
    }

    &:hover {
      polygon {
        fill: $white;
      }
    }
  }
}

.grey-800-svg {
  path {
    fill: $grey-800;
  }
}

.grey-600-svg {
  path {
    fill: $grey-600;
  }
}

.grey-400-svg {
  path {
    fill: $grey-400;
  }
}

.small-circle {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
}

.popin-shadow {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.wysiwyg-text {
  white-space: pre-wrap;

  p {
    min-height: 22px;
  }
  span,
  p,
  ul,
  li,
  ol {
    line-height: 22px !important;
    margin: 0 !important;
  }
  a {
    text-decoration: underline;
  }
}

.underline {
  text-decoration: underline;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overflow-auto {
  overflow: auto;
}

.left-border-grey {
  border-left: 1px solid $grey-400;
}

.low-opacity-hover {
  opacity: 1;

  &:hover {
    opacity: 0.6;
  }
}

.index-2 {
  z-index: 2;
}

.f-shrink-0 {
  flex-shrink: 0;
}

.line-height-13 {
  line-height: 1.3;
}

.w-50 {
  width: 49%;
}


.gm-style .gm-style-iw-c {
  max-width: 100vw !important;
  max-height: 100vh !important;
  padding: 0 !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
  max-height: 100vh !important;;
}

.gm-ui-hover-effect {
  width: 30px !important;
  height: 30px !important;
}

.gm-ui-hover-effect span {
  margin: 4px !important;
}