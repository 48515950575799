.rich-text-wrapper {
  background-color: transparent;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: #1f232c;
  font-weight: 500;
  border: 1px solid rgba(31, 35, 44, 0.12);
  border-radius: 5px;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 369px) {
    .input-label {
      margin-top: 10px;
    }
  }
}

.rich-text-toolbar {
  position: absolute;
  top: 0.25rem;
  left: 0;
  margin-left: 0.5rem;

  .rdw-option-wrapper {
    margin: 0.5rem;
  }
}
