@use "sass:math";
$max-width: 400;

.form-white-container {
  padding: 2rem 7rem;
  max-width: calc(100vw - 14rem);
}

@media screen and (max-width: 690px) {
  .form-white-container {
    padding: 1rem 2rem;
    padding-top: 3rem;
    max-width: calc(100vw - 4rem);
  }
}

.text-editor-large-width {
  width: 800px;
}

.form-input-width {
  width: $max-width * 1px;

  &.xsmall {
    width: (math.div($max-width, 3)) * 1px;
  }

  &.small {
    width: (math.div($max-width, 2)) * 1px;
  }

  &.medium {
    width: (math.div($max-width, 1.5)) * 1px;
  }
}

.form-figures-input-width {
  width: $max-width * 1.2px;

  &.small {
    width: (math.div($max-width, 2)) * 1px;
  }
}

.form-select-width {
  max-width: $max-width * 1.2px;
}

.form-file-input-width {
  width: $max-width * 1px;

  &.small {
    width: (math.div($max-width, 2)) * 1px;

    .button-file-name {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .button-file-icon {
      flex: initial;
    }
  }

  button {
    height: 64px;
  }

  input {
    height: 0;
  }
}

.form-file-text-area-width {
  width: $max-width * 2px;
}

.form-input-button {
  width: calc(#{$max-width * 1px} - 2rem);
}

@media screen and (max-width: 920px) {
  .form-file-text-area-width {
    width: $max-width * 1px;
  }
}

@media screen and (max-width: 530px) {
  .form-input-width {
    width: $max-width * 0.7px;

    &.small {
      width: (math.div($max-width, 2)) * 0.7px;
    }
  }

  .form-file-text-area-width {
    width: $max-width * 0.7px;
  }

  .form-file-input-width {
    width: $max-width * 0.7px;
  }

  .form-input-button {
    width: calc(#{$max-width * 0.7px} - 2rem);
  }
}

@media screen and (max-width: 320px) {
  .form-input-width {
    width: $max-width * 0.6px;
  }

  .form-file-text-area-width {
    width: $max-width * 0.6px;
  }

  .form-file-input-width {
    width: $max-width * 0.6px;

    &.small {
      width: (math.div($max-width, 2)) * 0.6px;
    }
  }

  .form-input-button {
    width: calc(#{$max-width * 0.6px} - 2rem);
  }
}
