.agency-panel:hover {
    border-color: $primary-red !important;
    border-width: 2px !important;
}

.contract-list-item {
    border: 1px solid #EDEEF1;
    transition: box-shadow 400ms;
    max-width: 1200px;
}

.contract-list-item:hover {
    border: 1px solid transparent;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
}
