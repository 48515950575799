.description-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: $grey-600 !important;
  font-size: 12px !important;
  text-align: left;
  word-break: break-all;
  max-height: 75px;

  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li,
  ol {
    line-height: 18px !important;
    font-weight: 400 !important;
    color: $grey-600 !important;
    font-size: 12px !important;
    margin: 0 !important;
  }
}
