.small-action-modal {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 40;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.5);

  .small-action-modal-content {
    background-color: $white;
    border-radius: 20px;
    padding: 2rem;
    max-width: calc(100vw - 6rem);
  }
}
