.current-page,
.paginationItem.selected {
  border: 1px solid $primary-red;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  color: $primary-red !important;
}

.pagination {
  display: flex;
  gap: 16px;
  flex-flow: column wrap;
  margin-top: 1rem;
}

.paginationResult {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;
}

@media (width <=768px) {
  .pagination {
    align-items: center;
  }

  .paginationResult {
    flex-direction: column;
  }
}

.paginationContainer {
  display: flex;
  flex-flow: row nowrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.paginationItem {
  --pagination-item-size: 40px;

  height: var(--pagination-item-size);
  min-width: var(--pagination-item-size);
  text-align: center;
  padding: 0;
  margin: auto 4px;
  color: #000;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: color 0.2s ease-in-out;
  font-weight: 500;
  color: #d01408;
}

.paginationItem.dots:hover {
  background-color: transparent;
  color: #000;
  cursor: default;
}

.paginationItem:hover {
  background-color: #d01408;
  color: #fff !important;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.paginationItem.disabled,
.paginationItem.disabled svg {
  pointer-events: none;
  border: none;
  color: $grey-300;
}

.paginationItem.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.paginationItem .paginationNavPicto {
  color: #d01408;
  width: 9px;
  height: 9px;
}

.paginationItem .paginationNavPrevious {
  transform: rotate(180deg);
}

.paginationItem:hover .paginationNavPrevious,
.paginationItem:hover .paginationNavNext {
  border-radius: 8px;
}

.ui-pagination__next:hover,
.ui-pagination__previous:hover {
  background-color: transparent !important;
  color: #d01408 !important;
}

.description {
  color: #000;
}

.pageSize {
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  gap: 16px;
  position: relative;
}

.pageSizeLabel {
  padding-left: 16px;
}

.pageSize select {
  color: #000;
  height: 100%;
  width: 55px;
  font-weight: 900;
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
}

.pageSize .pageSizeArrow {
  position: absolute;
  top: 50%;
  right: 0.125rem;
  transform: translate(0, -50%);
  pointer-events: none;
  z-index: 0;
  color: #000;
}

@media (max-width: 480px) {
  .paginationItem {
    margin: auto 0 !important;
  }
}