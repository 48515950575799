body {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
  color: $black;
  padding: 0;
  margin: 0;
}

p,
span {
  line-height: 28px;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
}

button {
  border: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
}

button:hover,
button:focus {
  outline: none;
}

button:focus {
  outline: none;
}

table {
  border-collapse: collapse;
}

table td {
  border: 1px solid $grey-100;
}

table tr td {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding: 1rem;
}

table tr th {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding: 1rem;
}

table tr:last-child td {
  border-bottom: 0;
}

table tr {
  text-align: center;
}

.table-container {
  overflow: auto;
}

.public-DraftEditorPlaceholder-root,
.public-DraftEditorPlaceholder-hasFocus {
  font-style: italic;
  color: #9197a3;
  opacity: 0.7;
}

.text-editor-popup-classname {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid rgba(31, 35, 44, 0.12);
  background: $grey-100;
  padding: 15px;
  border-radius: 5px;
  z-index: 100;

  input {
    border: 1px solid rgba(31, 35, 44, 0.12);
    border-radius: 5px;
    margin: 10px 5px;
    padding: 5px;
  }
}

.text-editor-titles {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
  position: relative;

  a {
    display: flex;
    align-items: center;

    .rdw-dropdown-carettoopen,
    .rdw-dropdown-carettoclose {
      border: solid $grey-800;
      margin: 0 0.5rem;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}

.dropdown-custom {
  background: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 0;
  text-transform: capitalize;
  border: 1px solid rgba(31, 35, 44, 0.12);
  background-color: $grey-100;
  position: absolute;
  top: 0.5rem;
  left: 0;
  z-index: 2;
  list-style: none;

  li {
    padding: 0.5rem;

    &:hover {
      background-color: $white;
    }
  }
}

.list {
  border-bottom: 1px solid #edeef1;
}

.list:last-child {
  border: none;
}

.panel {
  border-radius: 5px;
  padding: 2rem;
  max-width: calc(100vw - 6rem);
}

.panel-white {
  background-color: white;
}

.panel-dark {
  background-color: $grey-800;
}

.footer {
  transform: translateX(-70px);
}

.f-wrap {
  flex-wrap: wrap;
}

.f-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.f-full {
  flex: 1;
}

.first-letter-capitalize:first-letter {
  text-transform: uppercase;
}