.menu-icon {
  position: relative;
  >* {
    opacity: 0.5;
  }

  &:hover,
  &.active {
    >* {
      opacity: 1;
    }
  }
}

.menu-item-height {
  height: 55px;
}

.menu-container {
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;

  :first-child {
    transition: width 1s;

    img {
      transition: none;
    }
  }

  .full-size {
    transition: all 1s;
  }
}

.menu-container-no-animation {
  top: 0;
  left: 0;
  z-index: 2;
}

.menu-min-height {
  min-height: 100%;
}

.slash-container-shadow {
  > div > div:nth-child(2) > div:nth-child(1) {
    background-color: green;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  }
}

.menu-separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.menu-icon-count-tag {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.25rem;
  left: -1rem;
  background-color: $light-red;
  color: white;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  opacity: 1 !important;
}